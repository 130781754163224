<div class="mt-3 mx-3">
  <div class="my-3 d-flex">
    <h5 style="padding: 0; margin: 0;">IR Review Report</h5>
    <img class="ml-auto" style="cursor: pointer;" width="30px" src="assets/images/icons8-microsoft-excel.svg"
      alt="icon-excel" title="export excel" (click)="export()">
  </div>
  <div class="card bg-n7 mb-3" #SearchFilter>
    <div class="card-body">
      <h6 class="p-0 m-0;">
        <a (click)="collapse.toggle()" [attr.aria-expanded]="!isSearch" aria-controls="collapseExample">
          <fa-icon *ngIf="isSearch" [icon]="faAngleDown" class="mr-1"></fa-icon>
          <fa-icon *ngIf="!isSearch" [icon]="faAngleUp" class="mr-1"></fa-icon>
          Search Filters
        </a>
      </h6>
    <div class="mt-2 ml-4" id="search" #collapse="ngbCollapse" [(ngbCollapse)]="isSearch">
      <div class="row mb-1">
        <div class="col-12 col-sm-6 col-lg-4" #itDepartment>
          <span class="font-weight-bold ">IT Department</span>
          <form [formGroup]="form">
            <ng-select name="itDepartment" id="itDepartment" formControlName="itDepartment">
              <ng-option disabled [value]="">--Select Department--</ng-option>
              <ng-option *ngFor="let item of departmentList" [value]=item> {{item.deptName}}</ng-option>
            </ng-select>
          </form>
        </div>
        <div class="col-12 col-sm-6 col-lg-4" #MeetingDate>
          <span class="font-weight-bold">Meeting Date</span>
          <form class="form-inline">
            <div class="form-group hidden">
              <div class="input-group w-75">
                <input name="datepicker" class="invisible" style="height: 0px;" ngbDatepicker #datepicker="ngbDatepicker"
                  [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                  outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                <ng-template #t let-date let-focused="focused">
                  <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                    [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                    (mouseleave)="hoveredDate = null">
                    {{ date.day }}
                  </span>
                </ng-template>
              </div>
            </div>
            <div class="form-group">
              <div class="input-group">
                <input #dpFromDate class="form-control" [ngClass]="{'is-invalid': dateCriteriaInvalid}" style="max-width: 130px;" placeholder="dd/mm/yyyy" name="dpFromDate"
                  [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                  disabled>
                  <span class="btn date-range-symbol">-</span>
                  <input #dpToDate class="form-control" [ngClass]="{'is-invalid': dateCriteriaInvalid}" style="max-width: 130px;" placeholder="dd/mm/yyyy" name="dpToDate"
                  [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)"
                  disabled>
                <div class="input-group-append">
                  <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">
                    <fa-icon [icon]="faCalendarAlt"></fa-icon>
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-3">
        <button class="btn btn-link mr-2" type="button" (click)="clear()">Clear Filter</button>
        <button class="btn btn-primary" type="button" (click)="search()">Search</button>
      </div>
    </div>
    </div>  
  </div>
  <div #Table>
    <!-- <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs" >
      <li [ngbNavItem]="1">
        <a ngbNavLink>IR Review Report</a>
        <ng-template ngbNavContent> -->

          <div class="d-flex justify-content-end my-3">
            <app-row-per-page [size]=pageSize (newSize)="updatePageSize($event)">
            </app-row-per-page>
          </div>
        <div class="table-responsive" #servicesTable *ngIf="irReportDataSource.data">

          <table mat-table matSort class="table table-hover" cdk-table [dataSource]="irReportDataSource"
          (matSortChange)=onSortChange($event)>

          <ng-container cdkColumnDef="meetingDate">
            <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef mat-sort-header>Meeting Date</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.meetingDate}}</td>
          </ng-container>

          <ng-container cdkColumnDef="buDepartment">
            <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef mat-sort-header>Business Department</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.buDepartmentName}}</td>
          </ng-container>

          <ng-container cdkColumnDef="itDepartment">
            <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef mat-sort-header>IT Department</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.itDepartmentName}}</td>
          </ng-container>

          <ng-container cdkColumnDef="componentName">
            <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef mat-sort-header>Service</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.componentName}}</td>
          </ng-container>

          <ng-container cdkColumnDef="taskId">
            <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef mat-sort-header>Task ID</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.taskId | taskId}}</td>
          </ng-container>

          <ng-container cdkColumnDef="mainTaskName">
            <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef mat-sort-header>Main Task Name</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.mainTaskName}}</td>
          </ng-container>

          <ng-container cdkColumnDef="riskLevel">
            <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Risk Level</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.riskLevel}}</td>
          </ng-container>

          <ng-container cdkColumnDef="itEstimation">
            <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>IT Estimate</th>
            <td class="table-cell rightAlign" cdk-cell *cdkCellDef="let element">{{element.itEstimation | number}}</td>
          </ng-container>

          <ng-container cdkColumnDef="itOperationEstimation">
            <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>IT Oper Estimation</th>
            <td class="table-cell rightAlign" cdk-cell *cdkCellDef="let element">{{element.itOperationEstimation | number}}</td>
          </ng-container>

          <ng-container cdkColumnDef="securityEnhancement">
            <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Security Enhancement</th>
            <td class="table-cell rightAlign" cdk-cell *cdkCellDef="let element">{{element.securityEnhancement | number}}</td>
          </ng-container>

          <ng-container cdkColumnDef="budgetDetail">
            <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef >Budget Detail</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.budgetDetail}}</td>
          </ng-container>

          <ng-container cdkColumnDef="itExpenseValue">
            <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>IT Expense Value</th>
            <td class="table-cell rightAlign" cdk-cell *cdkCellDef="let element">{{element.itExpenseValue | number}}</td>
          </ng-container>

          <ng-container cdkColumnDef="expectedTargetDate">
            <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Expected Target Date</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.expectedTargetDate}}</td>
          </ng-container>
                
          <ng-container cdkColumnDef="architect">
            <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Architect</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.architect}}</td>
          </ng-container>

          <ng-container cdkColumnDef="security">
            <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Security</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.security}}</td>
          </ng-container>

          <ng-container cdkColumnDef="approved">
            <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Approved</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.approved}}</td>
          </ng-container>

          <ng-container cdkColumnDef="comment">
            <th class="table-header-cell" cdk-header-cell *cdkHeaderCellDef>Comment</th>
            <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.comment}}</td>
          </ng-container>

          <tr class="table-header" cdk-header-row *cdkHeaderRowDef="IrReportColumns"></tr>
          <tr class="table-row" cdk-row *cdkRowDef="let row; columns: IrReportColumns"></tr> 
          </table>  

          <div class="not-found text-center" *ngIf="irReportDataSource.data == null || totalElements === 0" >
            <div class="my-3"> No IR Data found.</div>
          </div>

        </div>

        <div class="bg-black-haze">
          <div class="align-self-center">
            <ngb-pagination class="d-flex justify-content-end pt-3 pr-2" [collectionSize]="totalElements"
              [(page)]="page" [maxSize]="5" [boundaryLinks]="true" [pageSize]="pageSize"
              (pageChange)="updatePage()">
            </ngb-pagination>
          </div>
        </div>

        <!-- </ng-template>
    </li>
    </ul>
    <div [ngbNavOutlet]="nav"></div> -->
  </div>
</div>
