<div class="mt-3 mx-3">
    <div class="my-3 d-flex">
      <h5 style="padding: 0; margin: 0;">Outstanding Task Report</h5>
      <img class="ml-auto" style="cursor: pointer;" width="30px" src="assets/images/icons8-microsoft-excel.svg"
      alt="icon-excel" title="export excel" (click)="export()">
    </div>
    <div class="card bg-n7 mb-3" #SearchFilter>
      <div class="card-body">
        <h6 class="p-0 m-0;">
          <a (click)="collapse.toggle()" [attr.aria-expanded]="!isSearch" aria-controls="collapseExample">
            <fa-icon *ngIf="isSearch" [icon]="faAngleDown" class="mr-1"></fa-icon>
            <fa-icon *ngIf="!isSearch" [icon]="faAngleUp" class="mr-1"></fa-icon>
            Search Filters
          </a>
        </h6>
      <div class="mt-2 ml-4" id="search" #collapse="ngbCollapse" [(ngbCollapse)]="isSearch">
        <div class="row mb-1">
          <div class="col-12 col-sm-6 col-lg-4" #itDepartment>
            <span class="font-weight-bold ">IT Department</span>
            <form [formGroup]="form">
              <ng-select name="itDepartment" id="itDepartment" formControlName="itDepartment">
                <ng-option disabled [value]="">--Select Department--</ng-option>
                <ng-option *ngFor="let item of itDepartmentList" [value]=item> {{item.deptName}}</ng-option>
              </ng-select>
            </form>
          </div>
          <div class="col-12 col-sm-6 col-lg-4" #buDepartment>
            <span class="font-weight-bold ">Support Department</span>
            <form [formGroup]="form">
              <ng-select name="itDepartment" id="buDepartment" formControlName="buDepartment">
                <ng-option disabled [value]="">--Select Department--</ng-option>
                <ng-option *ngFor="let item of buDepartmentList" [value]=item> {{item.deptName}}</ng-option>
              </ng-select>
            </form>
          </div>
          <div class="col-12 col-sm-6 col-lg-4" #CreatedDate>
            <span class="font-weight-bold">Created Date</span>
            <form class="form-inline">
              <div class="form-group hidden">
                <div class="input-group w-75">
                  <input name="datepicker" class="invisible" style="height: 0px;" ngbDatepicker #datepicker="ngbDatepicker"
                    [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t"
                    outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                  <ng-template #t let-date let-focused="focused">
                    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)"
                      [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date"
                      (mouseleave)="hoveredDate = null">
                      {{ date.day }}
                    </span>
                  </ng-template>
                </div>
              </div>
              <div class="form-group">
                <div class="input-group">
                  <input #dpFromDate class="form-control" [ngClass]="{'is-invalid': dateCriteriaInvalid}" style="max-width: 130px;" placeholder="dd/mm/yyyy" name="dpFromDate"
                    [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)"
                    disabled>
                    <span class="btn date-range-symbol">-</span>
                    <input #dpToDate class="form-control" [ngClass]="{'is-invalid': dateCriteriaInvalid}" style="max-width: 130px;" placeholder="dd/mm/yyyy" name="dpToDate"
                    [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)"
                    disabled>
                  <div class="input-group-append">
                    <button class="btn btn-outline-secondary" (click)="datepicker.toggle()" type="button">
                      <fa-icon [icon]="faCalendarAlt"></fa-icon>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="d-flex justify-content-end mt-3">
          <button class="btn btn-link mr-2" type="button" (click)="clear()">Clear Filter</button>
          <button class="btn btn-primary" type="button" (click)="search()">Search</button>
        </div>
      </div>
      </div>  
    </div>
    <div #Table>
  
            <div class="d-flex justify-content-end my-3">
                <app-row-per-page [size]=pageSize (newSize)="updatePageSize($event)">
                </app-row-per-page>
            </div>
            <div class="table-responsive" #servicesTable *ngIf="outstandingReportDataSource.data">
    
              <table mat-table matSort class="table table-hover" cdk-table [dataSource]="outstandingReportDataSource"
              (matSortChange)=onSortChange($event)>
  
              <ng-container cdkColumnDef="createdDate">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Created Date</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.createdDate}}</td>
              </ng-container>

              <ng-container cdkColumnDef="itDeptName">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >IT Department</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.itDeptName}}</td>
              </ng-container>
    
              <ng-container cdkColumnDef="serviceName">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Service Name</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.serviceName}}</td>
              </ng-container>
  
              <ng-container cdkColumnDef="taskType">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Type</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.taskType}}</td>
              </ng-container>

              <ng-container cdkColumnDef="taskId">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Task ID</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.taskId | taskId}}</td>
              </ng-container>
    
              <ng-container cdkColumnDef="mainTaskName">
                <th class="table-header-cell col-2 text-left" cdk-header-cell *cdkHeaderCellDef >Main Task Name</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.mainTaskName}}</td>
              </ng-container>    
  
              <ng-container cdkColumnDef="riskLevel">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Risk Level</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.riskLevel}}</td>
              </ng-container>

              <ng-container cdkColumnDef="status">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >status</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.status}}</td>
              </ng-container>
  
              <ng-container cdkColumnDef="createdByName">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Created By</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.createdByName}}</td>
              </ng-container>

              <ng-container cdkColumnDef="createdByUnitName">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Unit</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.buUnitName}}</td>
              </ng-container>
    
              <ng-container cdkColumnDef="createdByDepartmentName">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Department</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.buDepartmentName}}</td>
              </ng-container>  
    
              <ng-container cdkColumnDef="createdByDivisionName">
                <th class="table-header-cell text-left" cdk-header-cell *cdkHeaderCellDef >Division</th>
                <td class="table-cell" cdk-cell *cdkCellDef="let element">{{element.buDivisionName}}</td>
              </ng-container>  
    
              <ng-container cdkColumnDef="projectSize">
                <th class="table-header-cell " cdk-header-cell *cdkHeaderCellDef >Task Size</th>
                <td class="table-cell centerAlign" cdk-cell *cdkCellDef="let element">{{element.projectSize}}</td>
              </ng-container>  

              <ng-container cdkColumnDef="totalEstimationManday">
                <th class="table-header-cell rightAlign" cdk-header-cell *cdkHeaderCellDef >Total Man-day</th>
                <td class="table-cell rightAlign" cdk-cell *cdkCellDef="let element">{{element.totalEstimationManday | number}}</td>
              </ng-container>

              <ng-container cdkColumnDef="csEffort">
                <th class="table-header-cell rightAlign" cdk-header-cell *cdkHeaderCellDef >CS Effort</th>
                <td class="table-cell rightAlign" cdk-cell *cdkCellDef="let element">{{element.csEffort | number}}</td>
              </ng-container>

              <ng-container cdkColumnDef="solaEffort">
                <th class="table-header-cell rightAlign" cdk-header-cell *cdkHeaderCellDef >SOLA Effort</th>
                <td class="table-cell rightAlign" cdk-cell *cdkCellDef="let element">{{element.solaEffort | number}}</td>
              </ng-container>

              <ng-container cdkColumnDef="devEffort">
                <th class="table-header-cell rightAlign" cdk-header-cell *cdkHeaderCellDef >DEV Effort</th>
                <td class="table-cell rightAlign" cdk-cell *cdkCellDef="let element">{{element.devEffort | number}}</td>
              </ng-container>

              <ng-container cdkColumnDef="qcEstimation">
                <th class="table-header-cell rightAlign" cdk-header-cell *cdkHeaderCellDef >QC Effort</th>
                <td class="table-cell rightAlign" cdk-cell *cdkCellDef="let element">{{element.qcEstimation | number}}</td>
              </ng-container>

              <ng-container cdkColumnDef="itOperationEstimation">
                <th class="table-header-cell rightAlign" cdk-header-cell *cdkHeaderCellDef >IT Operation Effort</th>
                <td class="table-cell rightAlign" cdk-cell *cdkCellDef="let element">{{element.itOperationEstimation | number}}</td>
              </ng-container>

              <ng-container cdkColumnDef="securityEnhancement">
                <th class="table-header-cell rightAlign" cdk-header-cell *cdkHeaderCellDef >Security Enhancement</th>
                <td class="table-cell rightAlign" cdk-cell *cdkCellDef="let element">{{element.securityEnhancement | number}}</td>
              </ng-container>

              <ng-container cdkColumnDef="uatManDay">
                <th class="table-header-cell rightAlign" cdk-header-cell *cdkHeaderCellDef >UAT Effort</th>
                <td class="table-cell rightAlign" cdk-cell *cdkCellDef="let element">{{element.uatManDay | number}}</td>
              </ng-container>

              <ng-container cdkColumnDef="actualManDay">
                <th class="table-header-cell rightAlign" cdk-header-cell *cdkHeaderCellDef >Actual Effort</th>
                <td class="table-cell rightAlign" cdk-cell *cdkCellDef="let element">{{element.actualManDay | number}}</td>
              </ng-container>

              <tr class="table-header" cdk-header-row *cdkHeaderRowDef="OutstandingReportColumns"></tr>
              <tr class="table-row" cdk-row *cdkRowDef="let row; columns: OutstandingReportColumns"></tr> 
              </table>  
    
              <div class="not-found text-center" *ngIf="outstandingReportDataSource.data == null || totalElements === 0" >
                <div class="my-3"> No Outstanding Task found.</div>
              </div>
    
            </div>
  
            <div class="bg-black-haze">
              <div class="align-self-center">
                <ngb-pagination class="d-flex justify-content-end pt-3 pr-2" [collectionSize]="totalElements"
                  [(page)]="page" [maxSize]="5" [boundaryLinks]="true" [pageSize]="pageSize"
                  (pageChange)="updatePage()">
                </ngb-pagination>
              </div>
            </div>
      </div>
</div>