export const formLabels: any[] = [
  // Section Request Detail
  {
    key: 'itDepartment',
    label: 'IT Department'
  },
  {
    key: 'serviceName',
    label: 'Service Name'
  },
  {
    key: 'taskName',
    label: 'Task Name'
  },
  {
    key: 'customer',
    label: 'Customer Name/Broker No.'
  },
  {
    key: 'requireQuotation',
    label: 'Require Quotation'
  },
  {
    key: 'quotationNumber',
    label: 'Quotation Number'
  },
  {
    key: 'objectiveType',
    label: 'Objecttive Type'
  },
  {
    key: 'scopeOfWork',
    label: 'Scope Of Work'
  },
  {
    key: 'serviceRequestType',
    label: 'Service Request Type'
  },
  {
    key: 'dataOwner',
    label: 'Data Owner'
  },
  {
    key: 'endOfUsage',
    label: 'End of Usage'
  },
  {
    key: 'benefitDescription',
    label: 'Benefit Description'
  },
  {
    key: 'businessExpectedDate',
    label: 'Business Expected Date'
  },
  {
    key: 'businessValue',
    label: 'Business Value'
  },
  {
    key: 'requiredQC',
    label: 'Required QC'
  },
  {
    key: 'toSupportBusinessDepartment',
    label: 'To Support Business Department'
  },
  {
    key: 'estimateManDay',
    label: 'Estimate Manday'
  },
  {
    key: 'requiredUAT',
    label: 'Require UAT'
  },
  {
    key: 'sensitiveInformation',
    label: 'Impact to Personal and Sensitive Information'
  },
  {
    key: 'reason',
    label: 'Reason'
  },
  {
    key: 'changeId',
    label: 'Change ID'
  },
  {
    key: 'incidentId',
    label: 'Incident ID'
  },
  {
    key: 'planPromoteDate',
    label: 'Planed Promote Date and Time'
  },
  // End Section Request Detail
  // Section Impact Analysis
  {
    key: 'riskLevel',
    label: 'Risk Level'
  },
  {
    key: 'changeImpactAnalysis',
    label: 'Risk&Change Impact Analysis'
  },
  {
    key: 'architectureImpactAnalysis',
    label: 'Architecture Impact Analysis'
  },
  {
    key: 'securityConcern',
    label: 'Security Concern'
  },
  {
    key: 'reviewConcern',
    label: 'Security Review Concern'
  },
  {
    key: 'impactToCapacity',
    label: 'Impact to Capacity / Performance > 10%'
  },
  {
    key: 'impactToAvailability',
    label: 'Impact to Availability'
  },
  {
    key: 'csEffort',
    label: 'CS Effort (Man-days)'
  },
  {
    key: 'solaEffort',
    label: 'SOLA Effort (Man-days) '
  },
  {
    key: 'developerEstimation',
    label: 'Developer Estimation'
  },
  {
    key: 'qcEstimation',
    label: 'QC Estimation'
  },
  {
    key: 'functionalEstimation',
    label: 'Non-Functional Test Estimation'
  },
  {
    key: 'itOperationEstimation',
    label: 'IT Operation Estimation'
  },
  {
    key: 'securityEnhancement',
    label: 'Security Enhancement'
  },
  {
    key: 'uatMandays',
    label: 'UAT Man-days'
  },
  {
    key: 'mandayDescription',
    label: 'Manday Description'
  },
  // End Section Impact Analysis
  // Section Promote Form
  {
    key: 'taskId',
    label: 'Task Name'
  },
  {
    key: 'componentId',
    label: 'Released Service'
  },
  {
    key: 'expectedPromoteDate',
    label: 'Expected Promote Date'
  },
  {
    key: 'requireCab',
    label: 'Required Release Committee for Review'
  },
  {
    key: 'releaseCommittee',
    label: 'Require consideration by the full Release Committee'
  },
  {
    key: 'releaseType',
    label: 'Release'
  },
  {
    key: 'typeGroup',
    label: 'Type'
  },
  {
    key: 'testScriptGroup',
    label: 'Form #8 Test Script Form'
  },
  {
    key: 'developerSummary',
    label: 'Developer Summary'
  },
  {
    key: 'rollbackSummary',
    label: 'Rollback Plan'
  },
  {
    key: 'promoteProcedure',
    label: 'Promote Procedure'
  },
  {
    key: 'promoteSubject',
    label: 'Promote Subject'
  },
  {
    key: 'promoteDescription',
    label: 'Promote Description'
  },
  {
    key: 'impactLevel',
    label: 'Impact Level'
  },
  {
    key: 'riskLevelPromote',
    label: 'Risk Level'
  },
  // End Section Promote Form
  // Section Approve Detail
  {
    key: 'itHeadEr',
    label: 'IT Head'
  },
  {
    key: 'itHeadComment',
    label: 'IT Head Comment'
  },
  {
    key: 'cab',
    label: 'CAB'
  },
  {
    key: 'cabComment',
    label: 'CAB Comment'
  },
  {
    key:'comment',
    label:'Comment'
  },
  // End Section Approve Detail
  // Section Involve Person
  {
    key: 'mainBusinessUser',
    label: 'Main Business User'
  },
  {
    key: 'businessUnitHead',
    label: 'Business Unit Head'
  },
  {
    key: 'businessDeptHead',
    label: 'Business Department Head'
  },
  {
    key: 'uat',
    label: 'UAT'
  },
  {
    key: 'itHead',
    label: 'IT Head'
  },
  {
    key: 'sola',
    label: 'SOLA'
  },
  {
    key: 'qcHead',
    label: 'QC Head'
  },
  {
    key: 'uatHead',
    label: 'UAT Head'
  },
  {
    key: 'productionAcceptance',
    label: 'Production Acceptance'
  },
  {
    key: 'pqa',
    label: 'Production acceptance'
  },
  {
    key: 'se',
    label: 'SE'
  },
  // Section Action
  {
    key: 'action',
    label: 'Next Action'
  },
  {
    key: 'releaseDuration',
    label: 'Release Duration'
  },
  {
    key: 'acknowledgeAndAgree',
    label: 'Acknowledge/Recommend by Business'
  },
  {
    key: 'acknowledgeComment',
    label: 'Acknowledge/Recommend by Business Comment'
  }
];
